import { Injectable } from '@angular/core';
import { ApiDynamicService } from '../services/api-dynamic.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private ApiDynamic: ApiDynamicService) { }

  getdataservices(query: object) {

    return this.ApiDynamic.postDynamic('5698c0ef-712d-11e9-ad79-0800274c42e0', query);

  }
  
  getservicesid(query: object) {

    return this.ApiDynamic.postDynamic('66e3d3fc-712f-11e9-ad79-0800274c42e0', query);

  }

  
  
  gethomies(query: object) {

    return this.ApiDynamic.postDynamic('eb1a6cad-714c-11e9-ad79-0800274c42e0', query);

  }

  
  gestionservice(query: object) {

    return this.ApiDynamic.postDynamic('ad5e1644-715f-11e9-ad79-0800274c42e0', query);

  }

  getstatusservice(query:object){

    return this.ApiDynamic.postDynamic('f15174f9-71e1-11e9-ad79-0800274c42e0', query);
    
  }
}
