import { Component, OnInit } from '@angular/core';
import { Routes, ActivatedRoute, Params, Router } from '@angular/router';
import { DashboardService } from '../../services/dashboard.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-misservicios',
  templateUrl: './misservicios.component.html',
  styleUrls: ['./misservicios.component.scss'],
  providers: [UserService]
})
export class MisserviciosComponent implements OnInit {


  public identity = this._userService.getIdentity();
  public token = this._userService.getToken();
  public datalists : any;

  constructor(private _dashservice: DashboardService,private _userService: UserService) { }

  ngOnInit() {
    this.listservices();
  }


  listservices(){

    var obj ={
      'id':this.identity.sub
    }
  //  debugger
    this._dashservice.listservcustomer(obj).subscribe(

      response => {

        this.datalists = response;
          console.log(this.datalists);
      },
      error => {
        console.log(<any>error)
      }
    )


  }


}
