import { Component, OnInit } from '@angular/core';
import { Routes, ActivatedRoute, Params, Router } from '@angular/router';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service'
import { NotificationService } from '../../services/notification.service';


@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [UserService]
})

export class LoginComponent implements OnInit {

  
  public title: string;
  
  
  public user ={
    'email':null,
    'password':null,
  }



  public token;
  public identity;
  public status:string;
  
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private notify: NotificationService
  ) {
    this.title = 'Ingresa';
  

  }

  ngOnInit() {

    this.logout();

  }

  onSubmit(form) {

    console.log(this.user);
    debugger
     this._userService.signupcustomer(this.user).subscribe(

      response => {
        //Token
        if (response.status != 'Error') {
          this.status="Ingreso correcto";
          this.token = response;
         
          localStorage.setItem('token', this.token);
          //console.log(response);
          //Usuario
          this._userService.signupcustomer(this.user, true).subscribe(
            response => {
              this.identity = response;

              if(this.identity){
                localStorage.setItem('identity', JSON.stringify(this.identity));

                //Redireccion
                this.notify.showSuccess(this.status,'Bienvenido');
                this._router.navigate(['dashboard']);
                //console.log(response);
              }else{
                localStorage.removeItem('identity');
                localStorage.removeItem('token');
        
                this.identity=null;
                this.token=null;
                this.notify.showInfo('Tu usuario esta Inactivo','Lo sentimos');
                //Redireccion
                this._router.navigate(['']);
              }
             

            },
            error => {
              console.log(<any>error);
              this.notify.showError(response.status)
            }
          );
        }else{
          this.status ="Datos incorrectos";
          this.notify.showError(this.status,'Oops!');
        }


      },
      error => {
        console.log(<any>error);
      }
    ); 

  }

  logout(){
    this._route.params.subscribe(params=>{
      
      console.log("Entra a deslogueo");

      let logout = +params['sure'];
      
      if(logout == 1){
        localStorage.removeItem('identity');
        localStorage.removeItem('token');

        this.identity=null;
        this.token=null;
        this.notify.showInfo('Hasta pronto','Chao');
        //Redireccion
        this._router.navigate(['']);
      }
    })
  }

}
