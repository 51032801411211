import { Injectable } from '@angular/core';
import { Globals } from './global';
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class ApiDynamicService {

  constructor(private http: HttpClient, private host: Globals) { }

  /**
   * Execute method http
   * @param api URL action api
   * @param params object with parameters
   */
  put(api : string, params: Object) {
    return this.http.put(this.host.URL_SERVICE + api, params);
  }

  /**
   * Execute method http
   * @param api URL action api
   * @param params object with parameters
   */
  post(api : string, params : HttpParams) {
    return this.http.post(this.host.URL_SERVICE + api, params);
  }

  /**
   * Execute method http Post Dynamic
   * @param proce Giud of object
   * @param data object with parameters
   */
  postDynamic(proce : string ,data : object) {
    let param = new HttpParams();
    param = param.append('proc',proce);
    param = param.append('data',JSON.stringify(data));
    return this.http.post(this.host.URL_SERVICE + this.host.API_DYNAMIC, param);
  }

  /**
   * Execute method http
   * @param api URL action api
   */
  get(api : string) {
    return this.http.get(this.host.URL_SERVICE + api);
  }

  /**
   * Execute method http
   * @param api URL action api
   * @param params object with parameters
   */
  delete(api : string, params: Object) {
    return this.http.delete(this.host.URL_SERVICE + api, params);
  }

}
