import { Injectable } from '@angular/core';
import { ApiDynamicService } from '../services/api-dynamic.service';



@Injectable({
  providedIn: 'root'
})
export class DashboardService {
 


  constructor( private ApiDynamic: ApiDynamicService) { }


  getdatadash(query: object) {

    return this.ApiDynamic.postDynamic('abda0995-704d-11e9-97bf-0800274c42e0', query);

  }


  getdatahomies(query: object) {

    return this.ApiDynamic.postDynamic('c7a61d36-7056-11e9-97bf-0800274c42e0', query);

  }
 
  getdatacomments(query: object) {

    return this.ApiDynamic.postDynamic('ce825ecb-7060-11e9-97bf-0800274c42e0', query);

  }

  getdataservice(query: object){

    return this.ApiDynamic.postDynamic('6f2581e6-7077-11e9-97bf-0800274c42e0', query);

  }

 
  postservice(query: object){

    return this.ApiDynamic.postDynamic('55a5a1e7-8cfe-11e9-b37c-008cfa5afc28', query);

  }

  listservcustomer(query: object){

    return this.ApiDynamic.postDynamic('5fc13c9d-8d08-11e9-b37c-008cfa5afc28', query);

  }


}
