import { Component, OnInit } from '@angular/core';
import { Globals } from '../../services/global';
import { UserService } from '../../services/user.service';
import { Routes, ActivatedRoute, Params, Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  providers: [UserService]
})
export class DefaultLayoutComponent implements OnInit {

  public identity= this._userService.getIdentity();
  public token=this._userService.getToken();

  constructor(private global: Globals,
    private notify: NotificationService,
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _router:Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    
    if(this.identity == null){
     
      this.notify.showWarning("Ojo no tienes acceso","Sin Autorizacion");
      this._router.navigate([""]);
     
 

    } 

  }

}
