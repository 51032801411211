export class User{
    constructor(
        public tipo_id:string,
        public numeroid:string,
        public nombre:string,
        public email:string,
        public usuario:string,
        public telefonofijo:string,
        public telefonomovil:string,
        public password:string,
        public estado_id:string,
        public role_id:string
    ){
        

    }
}