import { Component, OnInit } from '@angular/core';
import { RegisterhojasService } from '../../services/registerhojas.service';

@Component({
  selector: 'app-hojas-vida',
  templateUrl: './hojas-vida.component.html',
  styleUrls: ['./hojas-vida.component.scss']
})
export class HojasVidaComponent implements OnInit {
  public datahojas: any;
  public title: string;
  constructor(private _hojaservice: RegisterhojasService) {
    this.title = 'Hojas de vida Registradas';
   }

  ngOnInit() {
    this.getdatahoja();
  }


  private getdatahoja() {

    var obj = {
      'id': ''
    }
    
    this._hojaservice.getdatahojas(obj).subscribe(

      response => {

        this.datahojas = response;
        //console.log(this.datahojas);
        //  this.datausers = [response];
      },
      error => {
        console.log(<any>error);
      }
    );
  }

}
