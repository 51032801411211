import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';
import { ServicesService } from '../../services/services.service';


@Component({
  selector: 'app-gestion',
  templateUrl: './gestion.component.html',
  styleUrls: ['./gestion.component.scss'],
  providers: [UserService]
})
export class GestionComponent implements OnInit {
  
    public homies:any;
    public statusservices:any;
    public setdata:{
      "id":''
    };
  
  public validate = false;
  public title: string;
  public user:any;
  public status: string;
  public dataservices = 
    { "idservicio": '', 
    "nombreproducto":'',
    "costoservicio":'',
    "nombrecliente":'',
    "emailcliente":'',
    "telefonocliente":'',
    "direccioncliente":'',
    "usuarioasignado":'',
    "calificacion":'',
    "created_at":'',
    "estado":'',
  
  };
  public senddata ={
    "idservicio":'',
    "iduser":'',
    "estado":''
  }

  public identity = this._userService.getIdentity();
  public token = this._userService.getToken();
  
  @ViewChild('registerForm') Form: NgForm;

  constructor(private _userService: UserService,
    private _route: ActivatedRoute,
    private _router: Router,
    private notify: NotificationService,
    private _serviceservice: ServicesService) { 
      this.title = 'Gesition de Servicios';
    //this.user = new User('1', '0', '', '', '', '0', '0', '', '1', '0');
    }

  ngOnInit() {
    
    this.getdataservice();
    this.gethomies();
    this.getstatus();
  }

  private getdataservice() {

    this._route.params.subscribe(params => {
      
      this.setdata = {
        'id':params['id']
      } 

      this._serviceservice.getservicesid(this.setdata).subscribe(

        response => {


         //   this.dataservices = response;
            this.dataservices = 
            { "idservicio": response[0].idservicio, 
            "nombreproducto":response[0].nombreproducto,
            "costoservicio":response[0].costoservicio,
            "nombrecliente":response[0].nombrecliente,
            "emailcliente":response[0].emailcliente,
            "telefonocliente":response[0].telefonocliente,
            "direccioncliente":response[0].direccioncliente,
            "usuarioasignado":response[0].tbm_customer_id,
            "calificacion":response[0].calificacion,
            "created_at":response[0].created_at,
            "estado":response[0].estado,
            
          }

             console.log(this.dataservices);
        //  this.datausers = [response];
            
        },
        error => {
          console.log(<any>error);
        }
      );

    });
        
  }
  
  private gethomies() {
      
      var obj = {
        'id': ''
      }

      this._serviceservice.gethomies(obj).subscribe(

        response => {

            this.homies = response;
                   
        },
        error => {
          console.log(<any>error);
        }
      );

  }

  private getstatus(){

    var obj ={
      'id':''
    }

    this._serviceservice.getstatusservice(obj).subscribe(
        response=>{
          this.statusservices = response;
         // console.log(this.statusservice);
        },

        error =>{
          console.log(<any>error);
        }

      )

  }
  

  onSubmit(form) {

    this.senddata ={
      "idservicio":this.dataservices.idservicio,
      "iduser":this.dataservices.usuarioasignado,
      "estado":this.dataservices.estado
    }
    

    this._serviceservice.gestionservice(this.senddata).subscribe(
      response => {

        this.status = response[0].CODE;

        if (this.status == '101') {

         this.notify.showSuccess(response[0].STATUS, response[0].LEVEL);
          form.reset();

          this._router.navigate(["/servicios"]);

        } else {

          this.notify.showError(response[0].STATUS, response[0].LEVEL);

        }
      },
      error => {
        console.log(<any>error);
      }
    );




  }


}
