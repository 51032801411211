export class Product{
    constructor(
        public id:string,
        public name_product:string,
        public description:string,
        public costo:number
    ){
        

    }
}