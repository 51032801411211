import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../services/global';
import { UserService } from '../services/user.service';
import { Routes, ActivatedRoute, Params, Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig,UserService]
})
export class NavbarComponent implements OnInit {

  public identity= this._userService.getIdentity();
  public token=this._userService.getToken();
  public sidebarOpened = false;
  toggleOffcanvas() {
    this.sidebarOpened = !this.sidebarOpened;
    if (this.sidebarOpened) {
      document.querySelector('.sidebar-offcanvas').classList.add('active');
    }
    else {
      document.querySelector('.sidebar-offcanvas').classList.remove('active');
    }
  }
  constructor(config: NgbDropdownConfig,
    private global: Globals,
    private notify: NotificationService,
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _router:Router) {
    
  }
  ngOnInit() {
    if(this.identity == null){
     
      this.notify.showWarning("Ojo no tienes acceso","Sin Autorizacion");
      this._router.navigate([""]);

    }
  }

}
