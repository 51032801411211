import { Injectable } from '@angular/core';
import { ApiDynamicService } from '../services/api-dynamic.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private ApiDynamic: ApiDynamicService) { }


  getdataproduct(query: object) {

    return this.ApiDynamic.postDynamic('6f2581e6-7077-11e9-97bf-0800274c42e0', query);

  }

  
  createdproduct(query: object) {

    return this.ApiDynamic.postDynamic('a506e98d-7087-11e9-97bf-0800274c42e0', query);

  }

  
  editproduct(query: object) {

    return this.ApiDynamic.postDynamic('937c390b-7110-11e9-ad79-0800274c42e0', query);

  }
  

  getdataproductid(query: object) {

    return this.ApiDynamic.postDynamic('219a5e87-7113-11e9-ad79-0800274c42e0', query);

  }

  
}
