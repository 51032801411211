import { Component, OnInit } from '@angular/core';
import { Globals } from '../services/global';
import { UserService } from '../services/user.service';
import { Routes, ActivatedRoute, Params, Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [UserService]
})
export class SidebarComponent implements OnInit {
  public samplePagesCollapsed = true;
  public identity= this._userService.getIdentity();
  public token=this._userService.getToken();

  constructor(private global: Globals,
    private notify: NotificationService,
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _router:Router) { }

  ngOnInit() {
    if(this.identity == null){
     
      this.notify.showWarning("Ojo no tienes acceso","Sin Autorizacion");
      this._router.navigate([""]);

    }
  }

}
