import { Injectable } from '@angular/core';
import { ApiDynamicService } from '../services/api-dynamic.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterhojasService {

  constructor(private ApiDynamic: ApiDynamicService) { }


  getdatahojas(query: object) {

    return this.ApiDynamic.postDynamic('77b65980-7163-11e9-ad79-0800274c42e0', query);

  }


}
