// globals.ts
import { Injectable } from '@angular/core';

@Injectable()

export class Globals {
    public procSeachView =  null;
    //url del servidor
    public URL_SERVICE = "https://www.helpinghome.com.co/apihelpinghome/public/api/"; 
    public API_DYNAMIC = "dynamic/app";
  //local http://api-helpinghome.com/api/
  //web:'https://www.helpinghome.com.co/apihelpinghome/public/api/'
  }  