import { Component, OnInit,ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { Routes, ActivatedRoute, Params, Router } from '@angular/router';
import { DashboardService } from '../services/dashboard.service';
import { NgForm } from "@angular/forms";
import { NotificationService } from '../services/notification.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../app.component.scss', './dashboard.component.scss'],
  providers: [UserService],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {


  
  @ViewChild('registerForm') Form: NgForm;

  
  public validate = false;


  public datausers: any;
  public datacoments: any;
  public service: string;
  public product: string;
  public homies: string;


  public identity = this._userService.getIdentity();
  public token = this._userService.getToken();


  public customer: string;
  public dataservice:any;

  public serviceone = {
    'id':null,
    'name_product':null,
    'description':null,
    'costo':null,
    'fecha':null,
    'hora':null
  };
  public servicetwo = {
    'id':null,
    'name_product':null,
    'description':null,
    'costo':null,
    'fecha':null,
    'hora':null
  };
  public servicethree = {
    'id':null,
    'name_product':null,
    'description':null,
    'costo':null,
    'fecha':null,
    'hora':null
  };

  public dataservicethree = {
    'tbm_products_id':null,
    'tbm_customer_id':null,
    'fecha':null,
    'hora':null
  }; 

  constructor(
    private _dashservice: DashboardService,
    private notify: NotificationService,
    private _userService: UserService,
    private _router: Router
  ) { }

  ngOnInit() {
    console.log('Dasboard cargado');
    this.getservices();
  }

  onSubmitc() {
//debugger

if(this.servicethree.fecha === null || this.servicethree.fecha === undefined || this.servicethree.hora === undefined || this.servicethree.hora === null){

  this.notify.showWarning("Debes seleccionar fecha y hora de reserva","Fecha");

}else{

  if (this.Form.valid) {
    this.validate = false;
    
      console.log(this.servicethree);

      this.dataservicethree ={
        'tbm_products_id':this.servicethree.id,
        'tbm_customer_id':this.identity.sub,
        'fecha':this.servicethree.fecha,
        'hora':this.servicethree.hora
    }

//    console.log(this.dataservicethree);
//debugger
        this._dashservice.postservice(this.dataservicethree).subscribe(

      response => {

        if (response[0].CODE == '101') {
         
          this.notify.showSuccess('Pronto un asesor se comunicara para ultimar detalles del servicio',response[0].LEVEL);
         this._router.navigate(['/misservicios']);
          this.validate = false;
         
        } else {
        
          this.notify.showError("Se presento un error con tu reserva, intenta de nuevo", "Upsss Error");
        }

      },
      error => {
        console.log(<any>error)
      }
    )
  } else {
    this.validate = true;

    console.log("campos requeridos");

  }
}



}

onSubmitb() {
  //debugger
  
  if(this.servicetwo.fecha === null || this.servicetwo.fecha === undefined || this.servicetwo.hora === undefined || this.servicetwo.hora === null){
  
    this.notify.showWarning("Debes seleccionar fecha y hora de reserva","Fecha");
  
  }else{
    if (this.Form.valid) {
      this.validate = false;
      
      //  console.log(this.servicetwo);
  
        this.dataservicethree ={
          'tbm_products_id':this.servicetwo.id,
          'tbm_customer_id':this.identity.sub,
          'fecha':this.servicetwo.fecha,
          'hora':this.servicetwo.hora
      }

    //  console.log(this.dataservicethree);

          this._dashservice.postservice(this.dataservicethree).subscribe(

        response => {

          if (response[0].CODE == '101') {
           
            this.notify.showSuccess('Pronto un asesor se comunicara para ultimar detalles del servicio',response[0].LEVEL);
            this._router.navigate(['/misservicios']);
            this.validate = false;
           
          } else {
          
            this.notify.showError("Se presento un error con tu reserva, intenta de nuevo", "Upsss Error");
          }

        },
        error => {
          console.log(<any>error)
        }
      )
    } else {
      this.validate = true;

      console.log("campos requeridos");

    }
  }
  
  
  
  
  }


  onSubmita() {
//    debugger
    
    if(this.serviceone.fecha === null || this.serviceone.fecha === undefined || this.serviceone.hora === undefined || this.serviceone.hora === null){
    
      this.notify.showWarning("Debes seleccionar fecha y hora de reserva","Fecha");
    
    }else{
      if (this.Form.valid) {
        this.validate = false;
        
     //     console.log(this.serviceone);
    
          this.dataservicethree ={
            'tbm_products_id':this.serviceone.id,
            'tbm_customer_id':this.identity.sub,
            'fecha':this.serviceone.fecha,
            'hora':this.serviceone.hora
        }
  
      //  console.log(this.dataservicethree);
  
            this._dashservice.postservice(this.dataservicethree).subscribe(
  
          response => {
  
            if (response[0].CODE == '101') {
             
              this.notify.showSuccess('Pronto un asesor se comunicara para ultimar detalles del servicio',response[0].LEVEL);
              this._router.navigate(['/misservicios']);
              this.validate = false;
             
            } else {
            
              this.notify.showError("Se presento un error con tu reserva, intenta de nuevo", "Upsss Error");
            }
  
          },
          error => {
            console.log(<any>error)
          }
        )
      } else {
        this.validate = true;
  
        console.log("campos requeridos");
  
      }


    }
    
    
    
   
    
    }

  private getservices(){
    var obj = {
      'id':''
    }
    this._dashservice.getdataservice(obj).subscribe(

      response => {
      
        this.dataservice = response;
        console.log(this.dataservice);

       this.serviceone = {
          'id':this.dataservice[0].id,
          'name_product':this.dataservice[0].name_product,
          'description':this.dataservice[0].description,
          'costo':this.dataservice[0].costo,
          'fecha':null,
          'hora':'08:00:00'
        };
        this.servicetwo = {
          'id':this.dataservice[1].id,
          'name_product':this.dataservice[1].name_product,
          'description':this.dataservice[1].description,
          'costo':this.dataservice[1].costo,
          'fecha':null,
          'hora':'08:00:00'
        };
        this.servicethree = {
          'id':this.dataservice[2].id,
          'name_product':this.dataservice[2].name_product,
          'description':this.dataservice[2].description,
          'costo':this.dataservice[2].costo,
          'fecha':null,
          'hora':'08:00:00'
        };

      },
      error => {
        console.log(<any>error);
      }
    );

  }




}
