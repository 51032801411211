import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../../services/services.service';


@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.scss']
})
export class ServiciosComponent implements OnInit {
  public dataservices: any;
  public title: string;
  constructor(private _serviceservice: ServicesService) { 
    this.title = 'Listado de Servicios';
  }

  ngOnInit() {

    this.getservices();
  }

  private getservices() {

    var obj = {
      'id': ''
    }
    
    this._serviceservice.getdataservices(obj).subscribe(

      response => {

        this.dataservices = response;
        console.log(this.dataservices);
        //  this.datausers = [response];
      },
      error => {
        console.log(<any>error);
      }
    );
  }

}
