import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';


@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})
export class ProductosComponent implements OnInit {
  public dataproducts: any;
  public title: string;
  constructor( private _productservice: ProductService) {
    this.title = 'Listado de Productos';
   }

  ngOnInit() {
   
  this.getdataproducto();

  }


  private getdataproducto() {

    var obj = {
      'id': ''
    }
    
    this._productservice.getdataproduct(obj).subscribe(

      response => {

        this.dataproducts = response;
        console.log(this.dataproducts);
        //  this.datausers = [response];
      },
      error => {
        console.log(<any>error);
      }
    );
  }

}
