import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';


@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
  providers: [UserService]
})
export class CreateUserComponent implements OnInit {
  public validate = false;
  public title: string;
  public user: User;
  public status: string;

  public identity= this._userService.getIdentity();
  public token=this._userService.getToken();

  @ViewChild('registerForm') Form: NgForm;


  constructor(
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _router: Router,
    private notify: NotificationService
  ) {
    this.title = 'Crear Usuario';
    this.user = new User('1', '0', '', '', '', '0', '0', '', '1', '0');
  }

  ngOnInit() {

    if(this.identity == null){
     
      this.notify.showWarning("Ojo no tienes acceso","Sin Autorizacion");
      this._router.navigate([""]);
     
 

    } 
  }

  onSubmit(form) {


    if (this.Form.valid) {
      this._userService.register(this.user).subscribe(
        response => {
          this.status = response.status;
          if (this.status == 'succes') {

            this.user = new User('1', '0', '', '', '', '0', '0', '', '1', '0');
            this.notify.showSuccess(response.message, 'Registro');
            form.reset();
            this._router.navigate(["/registro"]);

          } else {
            this.notify.showError(response.message, response.code);
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    } else {

      this.notify.showError('Llene los datos del formulario', 'Error de Validación')

    }




  }

}
