import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';



@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    providers: [UserService]
})

export class RegisterComponent implements OnInit {
    public title: string;
    public user: User;
    public status: string;
    public datausers:any;
    
    constructor(
        private _userService: UserService,
        private _route: ActivatedRoute,
        private _router: Router,
        private notify: NotificationService

    ) {
        this.title = 'Listado de Usuarios';
        this.user = new User('1', '0', '', '', '', '0', '0', '', '1', '0');
       // this.getdata();
    }

    ngOnInit() {
         console.log('Registro cargado');
         this.getdata();
    }



    private getdata() {
     
          var obj = {
            'id': ''
          }

          this._userService.getdata(obj).subscribe(
         
            response => {
            this.datausers = response;  
            console.log(this.datausers);
              //  this.datausers = [response];
            },
            error => {
                console.log(<any>error);
            }
        );       
      }

}