import { Component, OnInit ,ViewChild} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { User } from '../../models/user';
import { Product } from '../../models/product';
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';
import { ProductService } from '../../services/product.service';


@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
  providers: [UserService]
})
export class EditProductComponent implements OnInit {
  public validate = false;
  public title: string;
  public user: User;
  public status: string;
  public product: Product;
  public identity= this._userService.getIdentity();
  public token=this._userService.getToken();

  public productedit = 
    { "id": '', 
    "name_product":'',
    "description":'',
    "costo":0
  };

  @ViewChild('registerForm') Form: NgForm;
  constructor(private _userService: UserService,
    private _route: ActivatedRoute,
    private _router: Router,
    private notify: NotificationService,
    private _productservice: ProductService) { 
      this.title = 'Editar Producto';
      this.product = new Product('0', '', '', 0);
    }

  ngOnInit() {
    this.getproductid();
    if(this.identity == null){
     
      this.notify.showWarning("Ojo no tienes acceso","Sin Autorizacion");
      this._router.navigate([""]);
     
    } 
  }

  private getproductid() {

    this._route.params.subscribe(params => {

      
      var obj = {
        'id': +params['id']
      }
      
      this._productservice.getdataproductid(obj).subscribe(

        response => {

          // this.product = response;  

           this.productedit = 
            { 
              "id": response[0].id, 
              "name_product":response[0].name_product,
              "description":response[0].description,
              "costo":response[0].costo

          }
            
        },
        error => {
          console.log(<any>error);
        }
      );

    });
        
  }

  onSubmit(form) {


    if (this.Form.valid) {
      this._productservice.editproduct(this.productedit).subscribe(
        response => {

          this.status = response[0].CODE;

          if (this.status == '101') {

            this.product = new Product('0', '', '', 0);
           this.notify.showSuccess(response[0].STATUS, response[0].LEVEL);
            form.reset();
            this._router.navigate(["/productos"]);

          } else {
            this.notify.showError(response[0].message, response[0].code);
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    } else {

      this.notify.showError('Llene los datos del formulario', 'Error de Validación')

    }



  }

}
