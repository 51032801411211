import { Component, OnInit ,ViewChild} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { User } from '../../models/user';
import { Product } from '../../models/product';
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss'],
  providers: [UserService]
})
export class CreateProductComponent implements OnInit {
  public validate = false;
  public title: string;
  public user: User;
  public status: string;
  public product: Product;
  public identity= this._userService.getIdentity();
  public token=this._userService.getToken();

  @ViewChild('registerForm') Form: NgForm;

  constructor(
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _router: Router,
    private notify: NotificationService,
    private _productservice: ProductService
    
  ) {

    this.title = 'Crear Producto';
    this.product = new Product('0', '', '', 0);
   }

  ngOnInit() {
    if(this.identity == null){
     
      this.notify.showWarning("Ojo no tienes acceso","Sin Autorizacion");
      this._router.navigate([""]);
     
    } 
  }

  onSubmit(form) {


    if (this.Form.valid) {
      this._productservice.createdproduct(this.product).subscribe(
        response => {

          this.status = response[0].CODE;

          if (this.status == '101') {

            this.product = new Product('0', '', '', 0);
           this.notify.showSuccess(response[0].STATUS, response[0].LEVEL);
            form.reset();
            this._router.navigate(["/productos"]);

          } else {
            this.notify.showError(response[0].message, response[0].code);
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    } else {

      this.notify.showError('Llene los datos del formulario', 'Error de Validación')

    }

    




  }
  

}
