import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsComponent } from './forms/forms.component';
import { LoginComponent} from './components/login/login.component';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
import { RegisterComponent } from './components/register/register.component';
import { HojasVidaComponent } from './components/hojas-vida/hojas-vida.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { ProductosComponent } from './components/productos/productos.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { EditProductComponent } from './components/edit-product/edit-product.component';
import { GestionComponent } from './components/gestion/gestion.component';
import { RegisteruserComponent } from './components/registeruser/registeruser.component';
import { MisserviciosComponent } from './components/misservicios/misservicios.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' }
  ,
  {
    path:'registeruser',
    component:RegisteruserComponent,
  },
  {
    path: '',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
 
  { path: 'forms', component: FormsComponent },
  {
    path: '',
    component: DefaultLayoutComponent,
    
    data: {
      title: 'Home'
    },
    
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'misservicios',
        component: MisserviciosComponent
      },
      
      {
        path:'registro',
        component:RegisterComponent,
      },
      {
        path:'logout/:sure',
        component:LoginComponent,
      },
      {
        path: 'hoja',
        component:HojasVidaComponent,
      },
      {
        path: 'servicios',
        component:ServiciosComponent,
      },
      {
        path: 'productos',
        component:ProductosComponent,
      },
      {
        path: 'createuser',
        component:CreateUserComponent,
      },
      {
        path: 'edituser/:id',
        component:EditUserComponent,
      },
  
      {
        path: 'gestion/:id',
        component:GestionComponent,
      }

      
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
