import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
  providers: [UserService]
})
export class EditUserComponent implements OnInit {

  public validate = false;
  public title: string;
  public user:any;
  public status: string;
  public usuarioedit = 
    { "id": '', 
    "numeroid":'',
    "nombre":'',
    "email":'',
    "usuario":'',
    "telefonofijo":'',
    "telefonomovil":'',
    "estado_id":'',
    "role_id":''
  };

  public identity = this._userService.getIdentity();
  public token = this._userService.getToken();

  @ViewChild('registerForm') Form: NgForm;

  constructor(private _userService: UserService,
    private _route: ActivatedRoute,
    private _router: Router,
    private notify: NotificationService) {
    this.title = 'Editar Usuario';
    this.user = new User('1', '0', '', '', '', '0', '0', '', '1', '0');
  }

  ngOnInit() {
   this.getdatauser();
  }

  
  private getdatauser() {

    this._route.params.subscribe(params => {

      
      var obj = {
        'id': +params['id']
      }
      this._userService.getdataid(obj).subscribe(

        response => {

           this.user = response;  

           this.usuarioedit = 
            { "id": this.user[0].id, 
             "numeroid":this.user[0].numeroid,
            "nombre":this.user[0].nombre,
            "email":this.user[0].email,
            "usuario":this.user[0].usuario,
            "telefonofijo":this.user[0].telefonofijo,
            "telefonomovil":this.user[0].telefonomovil,
            "estado_id":this.user[0].estado_id,
            "role_id":this.user[0].role_id
          }
            
        },
        error => {
          console.log(<any>error);
        }
      );

    });
        
  }

  onSubmit(form) {



    if (this.Form.valid) {
      
      this._userService.edituser(this.usuarioedit).subscribe(
        response => {


        form.reset();
        this.notify.showSuccess(response[0].STATUS,response[0].LEVEL);

        this._router.navigate(["/registro"]);


        
        },
        error => {
          console.log(<any>error);
        }
      );
    } else {

      this.notify.showError('Llene los datos del formulario', 'Error de Validación')

    }

  }

  


}
