import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from './global';
import { User } from '../models/user'
import { ApiDynamicService } from '../services/api-dynamic.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class UserService {
  public url: string;
  public identity;
  public token;

  constructor(
    private ApiDynamic: ApiDynamicService
  ) {
    // this.url = GLOBAL.url;
  }

  
  register(user): Observable<any> {
    let params = new HttpParams();
    params = params.append('json', JSON.stringify(user));
    return this.ApiDynamic.post('registeruser', params);

  }


  registercustomer(user): Observable<any> {
    let params = new HttpParams();
    params = params.append('json', JSON.stringify(user));
    return this.ApiDynamic.post('registercustomer', params);

  }

  signupcustomer(user, gettoken = null): Observable<any> {

    if (gettoken != null) {
      user.gettoken = 'true';
    }

    let params = new HttpParams();
    params = params.append('json', JSON.stringify(user));
    return this.ApiDynamic.post('logincustomer', params);

  }


  signup(user, gettoken = null): Observable<any> {

    if (gettoken != null) {
      user.gettoken = 'true';
    }

    let params = new HttpParams();
    params = params.append('json', JSON.stringify(user));
    return this.ApiDynamic.post('login', params);

  }

  getIdentity() {
    let identity = JSON.parse(localStorage.getItem('identity'));

    if (identity != undefined) {
      this.identity = identity;
    } else {
      this.identity = null;
    }
    return this.identity;
  }

  getToken() {

    let token = localStorage.getItem('token');

    if (token != undefined) {
      this.token = token;
    } else {
      this.token = null;
    }
    return this.token;
  }

  //Traer datos de usuarios
  getdata(query: object) {

    return this.ApiDynamic.postDynamic('820deb00-6d43-11e9-b24a-0800274c42e0', query);

  }

  //Retorna dato de usuario con Id
  getdataid(query: object) {

    return this.ApiDynamic.postDynamic('72ea9931-701e-11e9-97bf-0800274c42e0', query);

  }

  //Edita un usuario seleccionado
  edituser(query: object){

    return this.ApiDynamic.postDynamic('52b07c23-6d5d-11e9-b24a-0800274c42e0', query);


  }


}