import { Component, OnInit, ViewChild } from '@angular/core';
import { Routes, ActivatedRoute, Params, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';

import { NgForm } from "@angular/forms";

@Component({
  selector: 'app-registeruser',
  templateUrl: './registeruser.component.html',
  styleUrls: ['./registeruser.component.scss'],
  providers: [UserService]
})
export class RegisteruserComponent implements OnInit {


  @ViewChild('registerForm') Form: NgForm;


  public validate = false;
  
  public user ={
    'name':null,
    'email':null,
    'phone':null,
    'address':null,
    'password':null,
  }

  constructor( private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private notify: NotificationService) { }

  ngOnInit() {
  }

  onSubmit() {

      if (this.Form.valid) {
        this.validate = false;
        console.log(this.user);
        this._userService.registercustomer(this.user).subscribe(
          response => {

            if (response.status == 'succes') {
             
              this.notify.showSuccess("Inicia sesion para realizar tu reserva", "Te registraste con exito");
              this._router.navigate(['/login']);
              this.validate = false;
             
            } else {
            
              this.notify.showError("Este registro ya existe, Verifique el Correo Electronico", "Upsss Error");
            }

          },
          error => {
            console.log(<any>error)
          }
        )

      } else {
        this.validate = true;
  
        console.log("campos requeridos");

      }

  }

}
