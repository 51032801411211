import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';

/* @Injectable({
  providedIn: 'root'
}) */
@Injectable()
export class NotificationService {


  constructor( public toastr: ToastrManager) {}

  public functionA : any;

  showSuccess(message : string,title? : string){
    this.toastr.successToastr(message,title,
      {
        position: 'bottom-right'
        ,showCloseButton:true
        ,animate:'slideFromRight'
      }
    );
  }
  

  showError(message : string, title? : string){
    this.toastr.errorToastr(message,title,
      {
        position: 'bottom-right'
        ,showCloseButton:true
        ,animate:'slideFromRight'
      }
    );
  }

  showWarning(message : string, title? : string){
    this.toastr.warningToastr(message,title,
      {
        position: 'bottom-right'
        ,showCloseButton:true
        ,animate:'slideFromRight'
      }
    );
  }

  showInfo(message : string, title? : string){
    this.toastr.infoToastr(message,title,
      {
        position: 'bottom-right'
        ,showCloseButton:true
        ,animate:'slideFromRight'
      }
    );
  }

  //
  showCustom() {
    this.toastr.customToastr(
    "<span style='color: green; font-size: 16px; text-align: center;'>Custom Toast</span>",
    null,
    { enableHTML: true }
    );
  }

}
